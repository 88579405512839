import { apiEndpoint } from 'app/scripts/fetch';

const Metatag = ({ content, pageUrl }) => {
  const metaImg = content?.includes('/uploads/')
    ? apiEndpoint(content)
    : content;

  const ServicePage = pageUrl?.includes('/service');

  return (
    <>
      <meta
        name="google-site-verification"
        content="Zurc1t-CPWSwjT_5Rs8B23mKUwQXZPgCXhqYOkJb-sY"
      />
      <script
        id="vtag-ai-js"
        async
        src="https://r2.leadsy.ai/tag.js"
        data-pid="ap0aXNAFBi4yc1P"
        data-version="062024"
      ></script>
      {process.env.NEXT_PUBLIC_APP_ENV === 'staging' && (
        <meta name="robots" content="noindex" />
      )}
      <meta charSet="utf-8" />
      <meta property="og:locale" content="en_US" />
      {ServicePage ? (
        <>
          <meta property="og:type" content="services" />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                name: 'Vlink',
                image: 'https://vlinkinfo.com/img/navbar-logo.svg',
                '@id': 'https://vlinkinfo.com',
                url: 'https://vlinkinfo.com',
                telephone: '+1 (860) 247-1400',
                priceRange: '$',
                address: [
                  {
                    '@type': 'PostalAddress',
                    streetAddress: '701 John Fitch Blvd, South Windsor',
                    addressLocality: 'Connecticut',
                    addressRegion: 'United States',
                    postalCode: '06074',
                    telephone: '+1 860-247-1400',
                    addressCountry: 'USA',
                  },
                  {
                    '@type': 'PostalAddress',
                    streetAddress: '233 Cochituate Rd #2, Framingham',
                    addressLocality: 'Massachusetts',
                    addressRegion: 'United States',
                    postalCode: '201301',
                    telephone: '+1 508-283-7977',
                    addressCountry: 'USA',
                  },
                ],
                openingHoursSpecification: {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ],
                  opens: '00:00',
                  closes: '23:59',
                },
                sameAs: [
                  'https://www.facebook.com/vlinkinc',
                  'https://x.com/VLinkInc',
                  'https://www.instagram.com/vlinkinc',
                  'https://www.youtube.com/channel/UCIts-lcy_h9hhboDZnroLFg',
                  'https://www.linkedin.com/company/vlink-inc',
                  'https://vlinkinfo.com',
                ],
              }),
            }}
          />
        </>
      ) : (
        <meta property="og:type" content="website" />
      )}

      <meta property="og:image" content={metaImg} />
      <meta property="og:image:secure_url" content={metaImg} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:type" content="image/webp" />
      <meta property="og:site_name" content="VLink" />
      <link rel="icon" href="/favicon.ico" />
      <meta
        httpEquiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />

      <link rel="alternate" hrefLang="en-US" href={pageUrl} />
      <link rel="alternate" href={pageUrl} hrefLang="x-default" />
    </>
  );
};

export default Metatag;
